import React from 'react';
import Head from 'next/head';
import { useIntl } from 'react-intl';
import { DefaultTheme, makeStyles } from 'groundkeeper-component-library';
import AuthenticationArea from 'shared/App/components/AuthenticationArea';
import Globals from 'shared/App/utils/Globals';

const useStyles = makeStyles((theme: DefaultTheme) => ({
  code: {
    fontSize: '10em',
    textAlign: 'center',
    color: theme.color.primary.main
  },
  message: {
    fontSize: '1.5em',
    textAlign: 'center'
  }
}));

function PageNotFound() {
  const intl = useIntl();
  const classes = useStyles();

  return (
    <>
      <Head>
        <title>{`${Globals.settings.titlePrefix}${intl.formatMessage({
          id: 'error_page_not_found',
          defaultMessage: 'Page not found'
        })}${Globals.settings.titlePostfix}`}</title>
      </Head>
      <AuthenticationArea />
      <h1 className={classes.code}>404</h1>
      <p className={classes.message}>
        {intl.formatMessage({
          id: 'error_page_not_found',
          defaultMessage: 'Page not found'
        })}
      </p>
    </>
  );
}

export default PageNotFound;
